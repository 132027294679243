import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { GraphqlClientService } from '@core/services/graphql-client.service';
import { FieldType } from '@ngx-formly/core';
import { Store } from '@ngxs/store';
import { AIR_FREIGHT, OCEAN_FREIGHT } from '@shared/constants';
import { GET_CONTAINER_TYPES_QUERY } from '@shared/gql-shared-queries';
import { catchError, Subject, take, takeUntil, tap } from 'rxjs';
import { JobFile } from '../../models/job-file.model';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { ShipmentMethodDetail } from '../../models/shipment-method-details';
import { WizardState } from '../../store/wizard.state';

enum ShipmentMethodType {
  FCL = 'FCL',
  LCL = 'LCL',
}
const NUMERIC_PATTERN = /^[0-9]+$/;
const MIN_VALUE = 1;
@Component({
  selector: 'hmt-service-shipment-method-selector',
  templateUrl: './service-shipment-method-selector.component.html',
  styleUrl: './service-shipment-method-selector.component.scss',
})

/**
 * This component handles shipment method selection for service cards.
 * Similar logic to order-shipment-method-selector.component.ts, but with service-specific
 * requirements and implementations.
 */
export class ServiceShipmentMethodSelectorComponent extends FieldType implements OnInit, OnDestroy {
  store = inject(Store);
  graphqlClient = inject(GraphqlClientService);
  fb = inject(FormBuilder);
  private destroy$ = new Subject<void>();

  selectedServiceCard$ = this.store.select(WizardState.getServiceCard);

  formGroup: FormGroup;

  initialLoadType: string = ShipmentMethodType.LCL;
  initialLoadDetails: ShipmentMethodDetail[] = [];
  disabled = false;
  isLongTermJob = false;
  radioHidden = false;
  isAirFreight = false;
  isSeaFreight = false;

  containerTypes: { id: string; name: string }[] = [];
  loadTypes = [{ type: ShipmentMethodType.FCL }, { type: ShipmentMethodType.LCL }];

  lclVolumeWeight = signal(0);
  airVolumeWeight = signal(0);

  ngOnInit(): void {
    this.handleFormStateInitialization();
    this.setupFormGroup();
    this.fetchContainerTypes();
    this.setupFormListeners();
    this.setupParentFormValidation();
    this.listenToServiceCardChanges();
  }

  private handleFormStateInitialization(): void {
    if (!this.formState.lsb) {
      this.disabled = true;
    }

    if (this.formState.isLongTermJob) {
      this.isLongTermJob = true;
    }
  }

  private setupFormGroup(): void {
    this.formGroup = this.fb.group({
      shipmentMethod: [this.initialLoadType],
      lcl: this.fb.array([]),
      fcl: this.fb.array([]),
    });
  }

  private setupParentFormValidation(): void {
    if (this.field.form instanceof FormGroup) {
      this.field.form.addControl('shipmentMethod', new FormControl());
      this.addRooValidators();
    } else {
      console.error('Form is not an instance of FormGroup');
    }
  }

  private setupFormListeners(): void {
    this.listenToFormValueChanges();
    this.listenToParentFormValueChanges();
  }

  /**
   * Listens to service card changes and initializes the form accordingly
   */
  private listenToServiceCardChanges(): void {
    this.selectedServiceCard$
      .pipe(
        take(1),
        tap(serviceCard => {
          if (serviceCard) {
            this.initializeFormFromServiceCard(serviceCard);
          }
        })
      )
      .subscribe();
  }

  /**
   * Initializes the form based on the service card data
   */
  private initializeFormFromServiceCard(serviceCard: ServiceCard): void {
    const { serviceId } = serviceCard;
    const { loadDetails = [] } = serviceCard;
    this.isAirFreight = serviceId === AIR_FREIGHT;
    this.isSeaFreight = serviceId === OCEAN_FREIGHT;
    this.initialLoadType = serviceCard?.shipmentMethod;
    if (this.isAirFreight) {
      this.radioHidden = true;
    }
    this.initializeForm(loadDetails);
  }

  /**
   * Initializes form with load details
   */
  private initializeForm(loadDetails: ShipmentMethodDetail[]): void {
    this.formGroup.get('shipmentMethod')?.setValue(this.initialLoadType);
    // Clear any existing arrays
    this.clearAllArray();

    if (this.isAirFreight) {
      const combinedLoadDetails = this.combineLoadDetails(loadDetails);
      this.addLCL(combinedLoadDetails);
    } else if (this.isSeaFreight) {
      if (this.initialLoadType === ShipmentMethodType.LCL) {
        const combinedLoadDetails = this.combineLoadDetails(loadDetails);
        this.addLCL(combinedLoadDetails);
      } else if (this.initialLoadType === ShipmentMethodType.FCL) {
        if (loadDetails.length > 0) {
          loadDetails.forEach(detail => {
            this.addFCL(detail);
          });
        } else {
          // If no load details are provided, add an empty FCL group
          this.addFCL();
        }
      }
    }
    this.notifyLoadDetailsChange();
  }

  private combineLoadDetails(loadDetails: ShipmentMethodDetail[]): ShipmentMethodDetail {
    return loadDetails.reduce(
      (acc, detail) => ({
        containerType: detail?.containerType ?? '',
        noOfLoads: acc.noOfLoads + (detail?.noOfLoads ?? 0),
        volumeWeight: acc.volumeWeight + (detail?.volumeWeight ?? 0),
      }),
      { containerType: null, noOfLoads: 0, volumeWeight: 0 }
    );
  }

  private listenToFormValueChanges(): void {
    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(_ => {
          this.notifyLoadDetailsChange();
        })
      )
      .subscribe();
  }

  private notifyLoadDetailsChange(): void {
    const loadDetails = this.getLoadDetailsFromForm();
    const shipmentMethod = this.formGroup.get('shipmentMethod')?.value;
    this.onLoadDetailsChange({
      loadDetails,
      shipmentMethod,
    });
  }

  /**
   * Gets load details from the form based on the shipment method
   */
  private getLoadDetailsFromForm(): ShipmentMethodDetail[] {
    const shipmentMethod = this.formGroup.get('shipmentMethod')?.value;

    if (shipmentMethod === ShipmentMethodType.FCL) {
      return this.fclArray.controls.map(control => (this.disabled ? control.getRawValue() : control.value));
    } else {
      return this.lclArray.controls.map(control => (this.disabled ? control.getRawValue() : control.value));
    }
  }

  private fetchContainerTypes(): void {
    this.graphqlClient
      .query<{ containerTypes: { _id: string; name: string }[] }, undefined>(GET_CONTAINER_TYPES_QUERY)
      .pipe(
        take(1),
        tap(response => {
          const containerTypes = response?.containerTypes;
          this.containerTypes = containerTypes.map(containerType => ({
            id: containerType._id,
            name: containerType.name,
          }));
        }),
        catchError(async err => {
          console.error('Error fetching container types', err);
          return [];
        })
      )
      .subscribe();
  }

  get lclArray(): FormArray {
    return this.formGroup.get('lcl') as FormArray;
  }

  get fclArray(): FormArray {
    return this.formGroup.get('fcl') as FormArray;
  }

  get shipmentMethod(): FormControl {
    return this.formGroup.get('shipmentMethod') as FormControl;
  }

  get getJobFile(): JobFile {
    return this.store.selectSnapshot(WizardState.getJobFile);
  }

  changeLoadType(event: MatRadioChange): void {
    this.formGroup.get('shipmentMethod')?.setValue(event.value);
    if (event.value === ShipmentMethodType.FCL) {
      this.clearAllArray();
      this.addFCL();
    } else {
      this.clearAllArray();
      this.addLCL();
    }
  }

  /**
   * Clears all form arrays
   */
  private clearAllArray(): void {
    this.fclArray.clear();
    this.lclArray.clear();
  }

  /**
   * Deletes an FCL item at the specified index
   */
  deleteItem(index: number): void {
    this.fclArray.removeAt(index);
  }

  /**
   * Creates an FCL form group with the provided load details
   */
  createFclGroup(loadDetails?: ShipmentMethodDetail): FormGroup {
    return this.fb.group({
      containerType: [
        { value: loadDetails?.containerType ?? null, disabled: this.disabled && this.isLongTermJob },
        Validators.required,
      ],
      noOfLoads: [
        { value: loadDetails?.noOfLoads ?? 0, disabled: this.disabled && this.isLongTermJob },
        [Validators.required, Validators.min(MIN_VALUE), Validators.pattern(NUMERIC_PATTERN)],
      ],
      volumeWeight: 0,
    });
  }

  /**
   * Creates an LCL form group with the provided load details
   */
  createLclGroup(loadDetails?: ShipmentMethodDetail): FormGroup {
    return this.fb.group({
      noOfLoads: [
        { value: loadDetails?.noOfLoads ?? 0, disabled: this.disabled && this.isLongTermJob },
        [Validators.required, Validators.min(MIN_VALUE), Validators.pattern(NUMERIC_PATTERN)],
      ],
      volumeWeight: [
        {
          value: loadDetails?.volumeWeight ?? (this.isAirFreight ? this.airVolumeWeight() : this.lclVolumeWeight()),
          disabled: this.disabled && this.isLongTermJob,
        },
        [Validators.required, Validators.min(MIN_VALUE), Validators.pattern(NUMERIC_PATTERN)],
      ],
      containerType: [
        { value: loadDetails?.containerType ?? '', disabled: this.disabled && this.isLongTermJob },
        this.isAirFreight ? [] : [Validators.required],
      ],
    });
  }

  /**
   * Adds a new LCL group to the form
   */
  addLCL(loadDetails?: ShipmentMethodDetail): void {
    const itemForm = this.createLclGroup(loadDetails);
    this.lclArray.push(itemForm);
  }

  /**
   * Adds a new FCL group to the form
   */
  addFCL(loadDetails?: ShipmentMethodDetail): void {
    const itemForm = this.createFclGroup(loadDetails);
    this.fclArray.push(itemForm);
  }

  /**
   * Handles changes to load details
   */
  onLoadDetailsChange(shipmentData: { loadDetails: ShipmentMethodDetail[]; shipmentMethod: string }): void {
    const { loadDetails, shipmentMethod } = shipmentData || {};
    if (!loadDetails || !shipmentMethod) return;

    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;

    // If the form is disabled, consider it valid
    if (this.disabled) {
      shipmentMethodControl?.setValue(shipmentMethod);
      shipmentMethodControl?.setErrors(null);
    }

    const isValid = this.validateShipmentData(shipmentMethod, loadDetails);

    if (isValid) {
      loadDetailsControl?.setValue(loadDetails);
      shipmentMethodControl?.setValue(shipmentMethod);
      loadDetailsControl?.setErrors(null);
      shipmentMethodControl?.setErrors(null);
    } else {
      loadDetailsControl?.setValue([]);
      shipmentMethodControl?.setValue(shipmentMethod);
      loadDetailsControl?.setErrors({ invalid: true });
      shipmentMethodControl?.setErrors({ invalid: true });
      loadDetailsControl?.markAsTouched();
      shipmentMethodControl?.markAsTouched();
    }

    // Force Angular to detect the change
    loadDetailsControl?.updateValueAndValidity();
    shipmentMethodControl?.updateValueAndValidity();
  }

  /**
   * Validates shipment data based on shipment method and load details
   */
  private validateShipmentData(shipmentMethod: string, loadDetails: ShipmentMethodDetail[]): boolean {
    if (shipmentMethod === ShipmentMethodType.FCL) {
      if (this.formState.lsb) {
        return true;
      }

      return loadDetails.length > 0 && loadDetails.every(detail => detail.containerType && detail.noOfLoads > 0);
    } else if (shipmentMethod === ShipmentMethodType.LCL) {
      return !loadDetails.some(
        detail =>
          detail.noOfLoads === 0 ||
          detail.noOfLoads === null ||
          detail.noOfLoads === undefined ||
          !detail.volumeWeight ||
          detail.volumeWeight <= 0
      );
    }
    return true;
  }

  addRooValidators(): void {
    const loadDetailsControl = this.field.form.controls['loadDetails'] as FormControl;
    // Make loadDetails control required
    if (loadDetailsControl) {
      if (this.getJobFile?.parentJobRefId) {
        loadDetailsControl.setValidators([Validators.required]);
      }
      loadDetailsControl.updateValueAndValidity();
      loadDetailsControl.markAsTouched();
      loadDetailsControl.markAsDirty();
    }

    // Make shipmentMethod control required
    const shipmentMethodControl = this.field.form.controls['shipmentMethod'] as FormControl;
    if (shipmentMethodControl) {
      if (this.getJobFile?.parentJobRefId) {
        shipmentMethodControl.setValidators([Validators.required]);
      }
      shipmentMethodControl.updateValueAndValidity();
      shipmentMethodControl.markAsTouched();
      shipmentMethodControl.markAsDirty();
    }
  }

  /**
   * Handle parent form value changes
   */
  private listenToParentFormValueChanges(): void {
    if (!this.form || !this.form.parent || !this.form.parent.controls['row-2']) {
      return;
    }

    const row2 = this.form.parent.controls['row-2'];
    const chargeableWight = row2.controls['chargeableWeight'] as FormControl;
    const volumeWeight = row2.controls['volumeWeight'] as FormControl;

    chargeableWight?.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(res => {
          this.airVolumeWeight.set(res);
          if (this.isAirFreight) {
            if (this.lclArray.controls.length > 0) {
              this.lclArray.controls[0].get('volumeWeight')?.setValue(res);
            }
          }
        })
      )
      .subscribe();

    volumeWeight?.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap(res => {
          this.lclVolumeWeight.set(res);
          if (!this.isAirFreight) {
            if (this.lclArray.controls.length > 0) {
              this.lclArray.controls[0].get('volumeWeight')?.setValue(res);
            }
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
