import { Component, OnInit } from '@angular/core';
import { Validators } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-create-job-file-external-party',
  templateUrl: './create-job-file-external-party.component.html',
  styleUrl: './create-job-file-external-party.component.scss',
})
export class CreateJobFileExternalPartyComponent extends FieldWrapper implements OnInit {
  isExternalToggled = false;

  constructor() {
    super();
  }
  ngOnInit(): void {
    console.log(this.formState);
  }

  toggleExternal() {
    this.form.valueChanges.subscribe(value => {
      console.log('value', value);
    });
    this.isExternalToggled = !this.isExternalToggled;
    if (this.isExternalToggled) {
      this.formControl.get('onBehalfOf')?.patchValue(true);
      this.formControl.get('customer')?.setValidators(Validators.required);
      this.formControl.get('customer')?.updateValueAndValidity();
      this.formControl.get('contract')?.setValidators(Validators.required);
      this.formControl.get('contract')?.updateValueAndValidity();
    } else {
      this.formControl.get('onBehalfOf')?.patchValue(false);
      this.formControl.get('customer')?.clearValidators();
      this.formControl.get('customer')?.updateValueAndValidity();
      this.formControl.get('contract')?.clearValidators();
      this.formControl.get('contract')?.updateValueAndValidity();
    }
  }
}
