import { Component, OnInit, signal } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Select } from '@ngxs/store';
import { ColDef } from 'ag-grid-community';
import { filter, Observable, take, tap } from 'rxjs';
import { PackingList } from '../../models/packing-list.model';
import { ServiceCard } from '../../models/service-card/service-card.model';
import { WizardState } from '../../store/wizard.state';

@Component({
  selector: 'hmt-shipment-packing-list-table',
  templateUrl: './shipment-packing-list-table.component.html',
  styleUrl: './shipment-packing-list-table.component.scss',
})
export class ShipmentPackingListTableComponent extends FieldType implements OnInit {
  transformedPackageList: PackingList<number, string>[] = [];
  columns: ColDef[] = [];
  disabled = signal(false);
  @Select(WizardState.getServiceCard) serviceCard$: Observable<ServiceCard>;

  columnNames: {
    header: string;
    field: string;
    type?: string;
    cellEditor?: string;
    cellEditorParams?: Record<string, unknown>;
  }[] = [
    {
      header: 'ItemRef Id',
      field: 'itemRefId',
      type: 'text',
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 100 },
    },
    {
      header: 'Commodity',
      field: 'commodity',
      type: 'text',
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 100 },
    },
    {
      header: 'HS Codes',
      field: 'hsCodes',
      type: 'text',
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 100 },
    },
    {
      header: 'Description Of Cargo',
      field: 'descriptionOfCargo',
      type: 'text',
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 250 },
    },
    {
      header: 'Packaging Type',
      field: 'packagingType',
      type: 'text',
      cellEditor: 'agTextCellEditor',
      cellEditorParams: { maxLength: 100 },
    },
    {
      header: 'No of Units',
      field: 'noOfUnits',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 0, step: 1, maxLength: 20 },
    },
    {
      header: 'Dimension(H)',
      field: 'dimensionH',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Dimension(W)',
      field: 'dimensionW',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Dimension(L)',
      field: 'dimensionL',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Weight Per Unit',
      field: 'weightPerUnit',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Gross Weight',
      field: 'grossWeight',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Net Weight',
      field: 'netWeight',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Chargeable Weight',
      field: 'chargeableWeight',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Volume',
      field: 'volume',
      type: 'number',
      cellEditor: 'agNumberCellEditor',
      cellEditorParams: { precision: 2, step: 0.01, maxLength: 20 },
    },
    {
      header: 'Dangerous Cargo',
      field: 'dangerousCargo',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Yes', 'No'] },
    },
    {
      header: 'Special Cargo',
      field: 'specialRequirements',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: { values: ['Yes', 'No'] },
    },
  ];

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!this.formState.lsb) {
      this.disabled.set(true);
    }

    this.generateColDefs();
    if (this.model && this.model.packingList) {
      this.transformedPackageList = this.modelToViewTransformer(this.model.packingList);
    } else {
      this.constructPackingList();
    }
  }

  generateColDefs(): void {
    this.columnNames.forEach(column => {
      this.columns.push({
        headerName: column.header,
        field: column.field,
        editable: true,
        cellDataType: column.type,
        cellEditor: column.cellEditor,
        cellEditorParams: column.cellEditorParams,
        valueSetter: params => {
          if (params.newValue) {
            params.data[params.colDef.field] = params.newValue;
            return true;
          }
          return false;
        },
        cellClassRules: {
          'cell-error': params => !params.value, // Add error class if value is empty
        },
        valueFormatter:
          column.type === 'number'
            ? params => (params.value != null ? Number(params.value).toFixed(2) : '')
            : undefined,
      });
    });
  }

  constructPackingList() {
    this.serviceCard$
      .pipe(
        filter(serviceCard => !!serviceCard),
        take(1),
        tap(serviceCard => {
          if (serviceCard && serviceCard?.cargoDetails?.packingList) {
            this.transformedPackageList = this.modelToViewTransformer(serviceCard?.cargoDetails?.packingList);
          }
        })
      )
      .subscribe();
  }

  modelToViewTransformer(packingList: PackingList<number, boolean>[]): PackingList<number, string>[] {
    return packingList.map(packingList => {
      return {
        orderRefNumber: packingList?.orderRefNumber,
        itemRefId: packingList?.itemRefId ?? '',
        commodity: packingList?.commodity ?? '',
        hsCodes: packingList?.hsCodes ?? '',
        descriptionOfCargo: packingList?.descriptionOfCargo ?? '',
        packagingType: packingList?.packagingType ?? '',
        noOfUnits: packingList?.noOfUnits ?? 0,
        dimensionH: packingList?.dimensionH ?? 0,
        dimensionW: packingList?.dimensionW ?? 0,
        dimensionL: packingList?.dimensionL ?? 0,
        weightPerUnit: packingList?.weightPerUnit ?? 0,
        grossWeight: packingList?.grossWeight ?? 0,
        netWeight: packingList?.netWeight ?? 0,
        chargeableWeight: packingList?.chargeableWeight ?? 0,
        volume: packingList?.volume ?? 0,
        dangerousCargo: this.booleanToYesNo(packingList?.dangerousCargo),
        specialRequirements: this.booleanToYesNo(packingList?.specialRequirements),
      };
    });
  }

  booleanToYesNo(value: boolean): string {
    return value ? 'Yes' : 'No';
  }

  patchDataToModel(packingList: PackingList<string, string>[]): void {
    if (!packingList || !packingList?.length) {
      return;
    }
    const transformedPackingList = packingList
      .filter(item => Object.keys(item).length > 0)
      .map(item => {
        return {
          orderRefNumber: item.orderRefNumber,
          itemRefId: item.itemRefId ?? '',
          commodity: item.commodity ?? '',
          hsCodes: item.hsCodes ?? '',
          descriptionOfCargo: item.descriptionOfCargo ?? '',
          packagingType: item.packagingType ?? '',
          noOfUnits: parseInt(item?.noOfUnits as unknown as string) ?? 0,
          dimensionH: parseFloat(item.dimensionH as unknown as string) ?? 0,
          dimensionW: parseFloat(item.dimensionW as unknown as string) ?? 0,
          dimensionL: parseFloat(item.dimensionL as unknown as string) ?? 0,
          weightPerUnit: parseFloat(item.weightPerUnit as unknown as string) ?? 0,
          grossWeight: parseFloat(item.grossWeight as unknown as string) ?? 0,
          netWeight: parseFloat(item.netWeight as unknown as string) ?? 0,
          chargeableWeight: parseFloat(item.chargeableWeight as unknown as string) ?? 0,
          volume: parseFloat(item.volume as unknown as string) ?? 0,
          dangerousCargo: item?.dangerousCargo === 'Yes',
          specialRequirements: item?.specialRequirements === 'Yes',
        };
      });
    if (transformedPackingList.length > 0) {
      this.updateCargoDetails(transformedPackingList);
    }
  }

  private updateCargoDetails(transformedPackingList): void {
    this.field.form.controls['packingListTable'].patchValue(transformedPackingList);
    const row1 = this.field.form.controls['row-1'];
    const row2 = this.field.form.controls['row-2'];
    const row3 = this.field.form.controls['row-3'];
    row1.controls['grossWeight'].patchValue(
      transformedPackingList.reduce((acc, curr) => {
        const value = curr.grossWeight ?? 0;
        return acc + (isNaN(value) ? 0 : value);
      }, 0)
    );
    row1.controls['netWeight'].patchValue(
      transformedPackingList.reduce((acc, curr) => {
        const value = curr.netWeight ?? 0;
        return acc + (isNaN(value) ? 0 : value);
      }, 0)
    );
    row2.controls['chargeableWeight'].patchValue(
      transformedPackingList.reduce((acc, curr) => {
        const value = curr.chargeableWeight ?? 0;
        return acc + (isNaN(value) ? 0 : value);
      }, 0)
    );
    row2.controls['volumeWeight'].patchValue(
      transformedPackingList.reduce((acc, curr) => {
        const value = curr.volume ?? 0;
        return acc + (isNaN(value) ? 0 : value);
      }, 0)
    );

    row3.controls['dangerousCargo'].patchValue(transformedPackingList.some(item => item.dangerousCargo));

    row3.controls['specialCargo'].patchValue(transformedPackingList.some(item => item.specialRequirements));
  }
}
