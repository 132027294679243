import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FieldWrapper } from '@ngx-formly/core';
import { DrayageType } from '@shared/models/drayage-type.model';
import { Subject, take, tap } from 'rxjs';
import { WizardService } from '../../services/wizard.service';

@Component({
  selector: 'hmt-drayage-selector',
  templateUrl: './drayage-selector.component.html',
  styleUrl: './drayage-selector.component.scss',
})
export class DrayageSelectorComponent extends FieldWrapper implements OnInit, OnDestroy {
  private unsubscribe$ = new Subject<void>();
  wizardService = inject(WizardService);

  drayageTypes = signal<DrayageType[]>([]);
  selectedDrayageType = signal<DrayageType | null>(null);
  drayageDisabled = signal<boolean>(false);

  ngOnInit(): void {
    this.drayageDisabled.set(!this.options.formState?.lsb);
    this.fetchDrayageTypes();
    this.addDrayageControls();
  }

  private getDrayageFormGroup(): FormGroup {
    return this.formControl as FormGroup;
  }

  private updateDrayageTypeValue(drayageTypeId: string | null): void {
    const formGroup = this.getDrayageFormGroup();
    formGroup.get('drayageType')?.setValue(drayageTypeId);
    this.model.drayageType = drayageTypeId;
    this.form.updateValueAndValidity();
  }

  private addDrayageControls(): void {
    const formGroup = this.getDrayageFormGroup();
    formGroup.addControl('hasDrayage', new FormControl(this.model?.hasDrayage ?? false));
    formGroup.addControl('drayageType', new FormControl(this.model?.drayageType ?? ''));
    this.form.updateValueAndValidity();
  }

  private setDrayageAvailability(availability: boolean): void {
    const formGroup = this.getDrayageFormGroup();
    formGroup.get('hasDrayage')?.setValue(availability);
    this.model.hasDrayage = availability;
    this.form.updateValueAndValidity();
  }

  private fetchDrayageTypes(): void {
    const serviceType = this.options?.formState?.serviceType;
    if (serviceType) {
      this.wizardService
        .fetchDrayageTypes(serviceType)
        .pipe(
          take(1),
          tap(drayageTypes => {
            this.drayageTypes.set(drayageTypes);
            if (this.model?.hasDrayage) {
              this.selectedDrayageType.set(
                this.drayageTypes().find(drayageType => drayageType._id === this.model?.drayageType)
              );
            }
          })
        )
        .subscribe();
    }
  }

  addDrayage(): void {
    this.selectedDrayageType.set(this.drayageTypes()[0]);
    this.setDrayageAvailability(true);
    this.updateDrayageTypeValue(this.selectedDrayageType()?._id);
  }

  setDrayageType(drayageType: DrayageType): void {
    this.selectedDrayageType.set(drayageType);
    this.updateDrayageTypeValue(drayageType?._id);
  }

  removeDrayage(): void {
    this.selectedDrayageType.set(null);
    this.updateDrayageTypeValue(null);
    this.setDrayageAvailability(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
