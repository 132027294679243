@if ((jobFile$ | async)?.smartened) {
  <div class="shipment-reference-container col">
    <div class="content col">
      <div *ngFor="let shipment of shipments$ | async">
        <mat-expansion-panel class="panel" [expanded]="expanded">
          <mat-expansion-panel-header class="header">
            <mat-panel-title class="title">
              <mat-checkbox
                [checked]="getShipmentStatus(shipment)?.selected"
                [disabled]="getShipmentStatus(shipment)?.disabled"
                (click)="$event.stopPropagation()"
                (keypress)="$event.stopPropagation()"
                (change)="onShipmentSelectionChange(shipment, $event.checked)"></mat-checkbox>
              <span class="title-placeholder">Shipment Reference No : </span> &nbsp;{{ shipment?.shipmentRefNumber }}
            </mat-panel-title>
            <button mat-flat-button class="view-order-btn" (click)="viewOrderData(shipment?.orders)">View Order</button>
          </mat-expansion-panel-header>
          <div class="content col services">
            <div *ngFor="let option of getOptionByShipmentId(shipment); let i = index">
              <mat-expansion-panel class="panel" [expanded]="true">
                <mat-expansion-panel-header class="header">
                  <mat-panel-title class="title">
                    <mat-checkbox
                      [checked]="getOptionStatus(shipment, option)?.selected"
                      [disabled]="getOptionStatus(shipment, option)?.disabled"
                      (change)="selectOption(shipment, option, $event.checked)"
                      (click)="$event.stopPropagation()"
                      (keypress)="$event.stopPropagation()">
                    </mat-checkbox>
                    <span class="title-placeholder">Option {{ i + 1 }} </span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="content col services">
                  <mat-accordion multi>
                    <mat-expansion-panel
                      *ngFor="let service of getServiceByOptionId(option)"
                      [expanded]="false"
                      class="no-shadow">
                      <mat-expansion-panel-header class="header">
                        <mat-panel-title class="service-title flex justify-between">
                          <div>
                            <mat-checkbox
                              [checked]="getServiceStatus(option, service)?.selected"
                              [disabled]="getServiceStatus(option, service)?.disabled"
                              (change)="onServiceSelectionChange(shipment, option, service, $event.checked)"
                              (click)="$event.stopPropagation()"
                              (keypress)="$event.stopPropagation()">
                            </mat-checkbox>
                            <span> {{ service?.serviceName }} </span>
                          </div>
                          <div class="flex items-center gap-10">
                            <div
                              class="tender-status"
                              [ngClass]="{
                                'tender-status-contracted': service?.tenderStatus === 'CONTRACTED',
                                'tender-status-rejected':
                                  service?.tenderStatus === 'REQUEST_REJECTED' ||
                                  service?.tenderStatus === 'REQUEST_EXPIRED'
                              }">
                              {{ tenderStatus[service?.tenderStatus] }}
                            </div>
                            @if (
                              service?.tenderStatus === 'PENDING_CONFIRMATION' || service?.tenderStatus === 'CONTRACTED'
                            ) {
                              <button
                                class="view-btn flex va-c"
                                (click)="viewLongTermContract(service?.plannedServiceId)">
                                <img src="assets/icons/eye.svg" alt="view" />
                              </button>
                            }
                          </div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
                  </mat-accordion>
                </div>
              </mat-expansion-panel>
            </div>
          </div>
        </mat-expansion-panel>
      </div>
    </div>
  </div>
} @else {
  <div class="assign-services-container">
    <div class="header">
      <mat-checkbox [(ngModel)]="allSelected" [disabled]="allDisabled" (change)="selectAll($event)">
        <span class="header-title">What Services Do You Need ?</span>
      </mat-checkbox>
    </div>
    <div class="content col">
      @for (serviceConfig of serviceConfigs; track serviceConfig._id) {
        <div class="service">
          <mat-checkbox
            [(ngModel)]="serviceConfig.selected"
            (change)="checkService(serviceConfig)"
            [disabled]="serviceConfig.disabled">
            <span class="header-title">{{ serviceConfig.serviceName }}</span>
          </mat-checkbox>
        </div>
      }
    </div>
  </div>
}
