import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { SCOPES } from '@configs/scopes';
import { checkScopesReactive } from '@core/guards/auth.guard';
import { DialogHandlerService } from '@core/services/dialog-handler.service';
import { Actions, ofActionSuccessful } from '@ngxs/store';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { EntityMap } from '@shared/types';
import { Subject, take, tap } from 'rxjs';
import { Order } from '../../models/order.model';
import { ServiceConfig } from '../../models/service-config.model';
import { ServiceInfo } from '../../models/service-info.model';
import { ServiceOption } from '../../models/service-option.model';
import { FetchAssignedServices } from '../../store/wizard.actions';
import { SelectServicesDrawerComponent } from '../select-services-drawer/select-services-drawer.component';

@Component({
  selector: 'hmt-select-services',
  templateUrl: './select-services.component.html',
  styleUrls: ['./select-services.component.scss'], // Changed to styleUrls (plural)
})
export class SelectServicesComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('scrollContainer', { static: false }) scrollContainer!: ElementRef;
  @Input() shipmentMethods: { key: string; value: string; transhipmentAllowed: boolean }[] = [];
  @Input() assignedServices: ServiceConfig[];
  @Input() orders: Order[] = [];
  @Input() shipmentServiceOptions: ServiceOption[] = [];
  @Input() defaultServiceConfigs: EntityMap<string, string[]>;
  @Input() parentJobRefId: string;
  @Input() isLongTermContractedJob: boolean;
  @Output() selectedService = new EventEmitter<{ serviceOptionId: string; serviceId: string; screenId: string }>();
  @Output() selectedShipmentMode = new EventEmitter<string>();
  @Output() selectedServiceConfigs = new EventEmitter<{
    shipmentMethod: string;
    selectedServiceConfigs: ServiceConfig[];
    serviceOptionId: string;
    previousPlannedServiceId?: string;
  }>();
  @Output() removeServiceOptionAction = new EventEmitter<string>();
  @Output() duplicateServiceOptionAction = new EventEmitter<string>();
  @Output() removeServiceCardAction = new EventEmitter<string>();

  wizardGeneralScopes$ = checkScopesReactive({ oneOf: [SCOPES.JOB_FILES.CREATE] });

  private $destroy = new Subject<void>();
  private renderer = inject(Renderer2);
  private dialog = inject(DialogHandlerService);
  private matDialog = inject(MatDialog);
  private fb = inject(FormBuilder);
  private actions$ = inject(Actions);

  formGroup: FormGroup;
  emptyFormGroup: FormGroup;
  createOptionDisabled = false;

  constructor() {}

  ngOnInit(): void {
    this.initForm();
    this.filterShipmentModes();
  }

  filterShipmentModes() {
    if (this.orders && this.orders.length) {
      if (!this.orders[0]?.shipmentDetails?.transhipment) {
        this.shipmentMethods = this.shipmentMethods.filter(mode => !mode?.transhipmentAllowed);
      }
    }
  }

  initForm(): void {
    if (this.orders[0]?.shipmentDetails?.preferredShipmentMode) {
      this.selectedShipmentMode.emit(this.orders[0]?.shipmentDetails?.preferredShipmentMode);
    }
    this.formGroup = this.fb.group({
      serviceOptions: this.fb.array([]),
    });
    this.emptyFormGroup = this.fb.group({
      shipmentMode: [this.orders[0]?.shipmentDetails?.preferredShipmentMode ?? ''],
    });

    if (this.parentJobRefId) {
      this.formGroup.get('shipmentMode')?.disable();
      this.emptyFormGroup.get('shipmentMode')?.disable();
    }

    if (this.shipmentServiceOptions && this.shipmentServiceOptions.length) {
      const serviceOptionsFormArray = this.formGroup.get('serviceOptions') as FormArray;
      this.shipmentServiceOptions.forEach(option => {
        serviceOptionsFormArray.push(this.createServiceOptionFormGroup(option));
      });

      this.createOptionDisabled = !this.shipmentServiceOptions?.every(option => option?.services?.length > 0);
    }
  }

  createServiceOptionFormGroup(option: ServiceOption): FormGroup {
    const formGroup = this.fb.group({
      _id: [option._id],
      shipmentMode: [option.shipmentMode || this.orders[0]?.shipmentDetails?.preferredShipmentMode || ''],
      services: [option.services],
    });
    if (this.parentJobRefId) {
      formGroup.get('shipmentMode')?.disable();
    }
    return formGroup;
  }

  get serviceOptionsFormArray(): FormArray {
    return this.formGroup.get('serviceOptions') as FormArray;
  }

  ngAfterViewInit(): void {
    this.updateJustifyContent();
    window.addEventListener('resize', () => this.updateJustifyContent());
  }

  onShipmentModeChange(index: number): void {
    const selectedMode = this.serviceOptionsFormArray.at(index).get('shipmentMode').value;
    this.selectedShipmentMode.emit(selectedMode);
  }

  onEmptyShipmentModeChange(event: MatSelectChange): void {
    this.selectedShipmentMode.emit(event.value);
  }

  openDialog(index: number, modifiedServices?: ServiceInfo[], previousPlannedServiceId?: string) {
    const emptyFormGroup = this.emptyFormGroup.value;
    const availableOptions = this.serviceOptionsFormArray.at(index);

    this.selectedShipmentMode.emit(
      availableOptions
        ? emptyFormGroup?.shipmentMode ?? availableOptions?.get('shipmentMode')?.value
        : emptyFormGroup?.shipmentMode
    );

    const data = {
      serviceConfigs: modifiedServices ?? this.assignedServices,
    };
    const dialogRef = this.dialog.openDialog(SelectServicesDrawerComponent, data, {
      height: '100%',
      width: '300px',
      position: { right: '0' },
    });

    dialogRef.componentInstance.selectedServices
      .pipe(
        take(1),
        tap((selectedServices: ServiceConfig[]) => {
          this.selectedServiceConfigs.emit({
            shipmentMethod: availableOptions
              ? availableOptions?.get('shipmentMode')?.value
              : emptyFormGroup?.shipmentMode,
            selectedServiceConfigs: selectedServices,
            serviceOptionId: availableOptions ? availableOptions?.get('_id')?.value : '',
            previousPlannedServiceId: previousPlannedServiceId,
          });
        })
      )
      .subscribe();
  }

  openServiceView(index: number, service: ServiceInfo) {
    const option = this.serviceOptionsFormArray.at(index).value;
    this.selectedService.emit({
      serviceOptionId: option._id,
      serviceId: service.plannedServiceId,
      screenId: service.serviceUIId,
    });
  }

  updateJustifyContent() {
    const container = this.scrollContainer?.nativeElement;
    if (!container) return;
    const totalItemsWidth = Array.from(container.children)
      .map((child: unknown) => (child as HTMLElement).offsetWidth)
      .reduce((total, width) => total + width, 0);

    if (totalItemsWidth < container.clientWidth) {
      this.renderer.addClass(container, 'center');
      this.renderer.removeClass(container, 'start');
    } else {
      this.renderer.addClass(container, 'start');
      this.renderer.removeClass(container, 'center');
    }
  }

  removeServiceOption(index: number) {
    const optionId = this.serviceOptionsFormArray.at(index).get('_id').value;
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        success: false,
        title: 'Delete Service Option',
        message: 'Are you sure you want to delete this service option?',
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirmCallback: () => {
          this.removeServiceOptionAction.emit(optionId);
          this.serviceOptionsFormArray.removeAt(index);
        },
      },
    });
  }

  createServiceOption(): void {
    (this.formGroup.get('serviceOptions') as FormArray).push(
      this.createServiceOptionFormGroup({
        _id: '',
        shipmentMode: this.orders[0]?.shipmentDetails?.preferredShipmentMode ?? '',
        services: [],
      })
    );
    this.createOptionDisabled = true;
  }

  duplicateServiceOption(option: FormGroup) {
    this.duplicateServiceOptionAction.emit(option.get('_id')?.value);
  }

  deleteServiceCardDisabled(option: FormGroup): boolean {
    if (this.orders && this.orders?.length) {
      //transhipment status is checked from the first order because all orders have same shipment mode.
      if (this.orders[0]?.shipmentDetails?.transhipment) {
        const services = option.get('services')?.value ?? [];
        return services.length === 1;
      } else {
        return true;
      }
    }
    return false;
  }
  //TODO: remove this ASAP when service options other than sea air combination are instroduced
  addServiceCardDisabled(option: FormGroup, index?: number): boolean {
    if (this.isLongTermContractedJob) {
      return true;
    }

    if (this.orders && this.orders?.length) {
      if (this.orders[0]?.shipmentDetails?.transhipment) {
        const services: ServiceInfo[] = option.get('services')?.value ?? [];
        // in the current phase only 2 service cards are allowed. remove this condition in coming phases.
        if (services.length === 2) {
          return true;
        } else {
          //assuming there are 2 service cards in the assigned services. will change in future
          const defaultServices = this.defaultServiceConfigs[option.get('shipmentMode')?.value];
          //check for SEA or AIR services
          if (defaultServices.length === 1) {
            if (defaultServices[0] === services[0].serviceId) {
              return false;
            } else {
              return true;
            }
          }

          const missingServices = defaultServices.filter(service => !services.some(s => s.serviceId === service));
          const missingServiceIndex = defaultServices.findIndex(service => missingServices.includes(service));

          return missingServiceIndex !== index;
        }
      } else {
        return true;
      }
    }
    return false;
  }

  deleteServiceCard(service: ServiceInfo) {
    this.matDialog.open(ConfirmationDialogComponent, {
      data: {
        success: false,
        title: 'Delete Service Card',
        message: 'Are you sure you want to delete this service card?',
        confirmButtonText: 'Yes',
        showCancel: true,
        onConfirmCallback: () => this.removeServiceCardAction.emit(service?.plannedServiceId),
      },
    });
  }

  addServiceCard(serviceOptionIndex: number, serviceIndex: number, service: ServiceInfo) {
    const option = this.serviceOptionsFormArray.at(serviceOptionIndex).value;
    this.selectedShipmentMode.emit(option?.shipmentMode);

    this.actions$
      .pipe(
        ofActionSuccessful(FetchAssignedServices),
        take(1),
        tap((_: FetchAssignedServices) => {
          const previousPlannedServiceId = serviceIndex ? service.plannedServiceId : '';

          const defaultServices = this.defaultServiceConfigs[option.shipmentMode];
          if (defaultServices.length === 1) {
            this.openDialog(
              serviceOptionIndex,
              defaultServices.map(service => ({
                ...this.assignedServices.find(srv => srv._id === service),
                disabled: false,
              })),
              previousPlannedServiceId
            );
            return;
          }
          const missingServices = defaultServices.filter(
            service => !option.services.some(s => s.serviceId === service)
          );
          const modifiedServices = defaultServices.map(service => ({
            ...this.assignedServices.find(srv => srv._id === service),
            disabled: !missingServices.includes(service),
          }));
          this.openDialog(serviceOptionIndex, modifiedServices, previousPlannedServiceId);
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
