@if (this.options?.formState?.drayageEnabled) {
  <div class="drayage-selector-container">
    @if (!selectedDrayageType()) {
      <div class="flex items-center gap-10">
        <button
          [disabled]="drayageDisabled()"
          [ngClass]="{ 'disabled-button': drayageDisabled() }"
          mat-mini-fab
          class="action-button add-btn"
          (click)="addDrayage()">
          <mat-icon class="icon">add</mat-icon>
        </button>
        <span class="action-button-text">Add Drayage</span>
        <div class="horizontal-line"></div>
      </div>
    } @else {
      <div class="flex items-center gap-10">
        <button
          [disabled]="drayageDisabled()"
          [ngClass]="{ 'disabled-button': drayageDisabled() }"
          mat-mini-fab
          class="action-button remove-btn"
          (click)="removeDrayage()">
          <mat-icon class="icon">remove</mat-icon>
        </button>
        <span class="action-button-text">Remove Drayage</span>
        <div class="horizontal-line"></div>
      </div>
      <div class="select-label">Select Drayage Type</div>
      <mat-radio-group
        [disabled]="drayageDisabled()"
        class="flex col gap-2 custom-radio-group"
        [ngModel]="selectedDrayageType()"
        (ngModelChange)="setDrayageType($event)">
        @for (item of drayageTypes(); track $index) {
          <mat-radio-button [value]="item">
            {{ item.description }}
          </mat-radio-button>
        }
      </mat-radio-group>
    }
  </div>
}
