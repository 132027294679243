<mat-expansion-panel class="shipment-container" [expanded]="isFirstCard">
  <mat-expansion-panel-header class="shipment-header">
    <div class="shipment-title mat-expansion-panel-header-description">
      <mat-icon>expand_more</mat-icon>
      <span>{{ shipment?.shipmentRefNumber }}</span>
    </div>
    <div class="shipment-buttons">
      <button
        (click)="importOrders()"
        class="import-btn"
        mat-stroked-button
        color="primary"
        [disabled]="servicesEmpty"
        [ngClass]="{
          'disabled-button': (wizardGeneralScopes$ | async) ? false : true
        }">
        <mat-icon>file_download</mat-icon>
        Import Orders
      </button>
      <button
        (click)="deleteShipment($event)"
        class="delete-btn"
        mat-mini-fab
        aria-label="Delete"
        [ngClass]="{
          'disabled-button': (wizardGeneralScopes$ | async) ? false : true
        }">
        <img src="./assets/icons/delete.svg" alt="delete service icon" />
      </button>
    </div>
  </mat-expansion-panel-header>
  <div class="shipment-body">
    <div class="search-row">
      <span>Orders</span>
      <mat-form-field appearance="outline" class="dense-3">
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" [formControl]="searchControl" />
      </mat-form-field>
    </div>
    <div class="empty-orders-view" *ngIf="!shipment?.orders || !shipment?.orders?.length">
      <h2>Import Orders</h2>
      <p>Import the orders that you want to include in this shipment.</p>
      <button
        (click)="importOrders()"
        mat-raised-button
        color="primary"
        [disabled]="servicesEmpty"
        [ngClass]="{
          'disabled-button': (wizardGeneralScopes$ | async) ? false : true
        }">
        <mat-icon>file_download</mat-icon>
        Import Orders
      </button>
    </div>
    <div class="selected-orders-list-view">
      <div *ngFor="let order of (ordersOfShipment$ | async)?.items">
        <hmt-order-card
          [viewOnly]="true"
          [hideCheckBox]="true"
          [order]="order"
          [deletable]="true"
          [disableDelete]="servicesEmpty"
          (deleteOrderAction)="removeOrderFromShipment($event)"></hmt-order-card>
      </div>
      <mat-paginator
        class="paginator"
        [length]="(ordersOfShipment$ | async)?.pageInfo?.total"
        [pageSize]="(ordersOfShipment$ | async)?.pageInfo?.pageSize"
        [pageSizeOptions]="PAGE_SIZE_OPTIONS"
        (page)="onPageChange($event)"></mat-paginator>
    </div>
    <div class="select-services-view">
      <hmt-select-services
        [shipmentMethods]="shipmentMethods"
        [orders]="shipment?.orders"
        [shipmentServiceOptions]="serviceOptions"
        [defaultServiceConfigs]="defaultServiceConfigs"
        [parentJobRefId]="(jobFile$ | async)?.parentJobRefId"
        [isLongTermContractedJob]="!!(jobFile$ | async)?.longTermContractId"
        (selectedServiceConfigs)="setSelectedServiceConfigs($event)"
        (selectedShipmentMode)="setSelectedShipmentMode($event)"
        [assignedServices]="assignedServices | async"
        (selectedService)="setSelectedService($event)"
        (removeServiceOptionAction)="removeServiceOption($event)"
        (duplicateServiceOptionAction)="duplicateServiceOption($event)"
        (removeServiceCardAction)="removeServiceCard($event)"></hmt-select-services>
    </div>
  </div>
</mat-expansion-panel>
