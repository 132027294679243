import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { Pageable } from '@shared/models/pageable.model';
import { WorkflowScreen } from 'app/json-schema-forms/store/models/workflow-screen.model';
import { Observable } from 'rxjs';
import { Order } from '../../models/order.model';
import { SelectableOrder } from '../../models/selecrtable-order.model';

@Component({
  selector: 'hmt-orders-container',
  templateUrl: './orders-container.component.html',
  styleUrl: './orders-container.component.scss',
})
export class OrdersContainerComponent extends FieldWrapper {
  selectableOrders: SelectableOrder[] = [];
  @Input() set orders(orders: Pageable<Order>) {
    if (orders) {
      this.selectableOrders = orders.items.map(order => {
        return {
          ...order,
          selected: false,
        };
      });
    }
  }
  @Input() parentJobRefId: string;
  @Input() injectedScreen: Observable<WorkflowScreen>;
  @Output() editOrderAction = new EventEmitter<Order>();
  @Output() deleteOrderAction = new EventEmitter<Order>();
  @Output() selectedOrders = new EventEmitter<{ shipmentId: string; orders: Order[] }>();

  editOrder(order: Order): void {
    this.editOrderAction.emit(order);
  }
  deleteOrder(order: Order): void {
    this.deleteOrderAction.emit(order);
  }
}
